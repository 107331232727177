import router from '@/router'
import { getAuthInfo } from '@/api/login'
import { getUrlParam } from '@/utils/utils'
import axios from '@/utils/axios'
let info = null
router.beforeEach(async (to, from, next) => {
  // 拦截ticket授权
  var obj = getUrlParam(window.location.href)
  if (obj.ticket) {
    localStorage.setItem('Authorization', obj.ticket)
  }
  if (!info) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('Authorization')
    info = await getAuthInfo()
    localStorage.setItem('userInfo', JSON.stringify(info.data))
  }
  if (to.path === '/myMeeting') {
    next()
  } else if (info && info.data && !info.data.roleLength) { // 普通用户跳转
//   } else if (info && info.data) { // 普通用户跳转
    next('/myMeeting')
  } else {
    next()
  }
})
