const zhCn = {
  header: {
    navs: {
      user: '主持人管理',
      meeting: '会议管理',
      myMeeting: '我的会议'
    },
    button: {
      login: '登录'
    }
  },
  table: {
    emptyText: '暂无数据'
  },
  userPage: {
    title: '主持人管理'
  },
  userDetailPage: {
    title: '主持人详情'
  },
  meetingPage: {
    title: '会议管理'
  },
  meetingDetailPage: {
    title: '会议详情'
  }
}
export default zhCn
