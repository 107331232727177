import ajax from '@/utils/axios'

// 获取用户信息
export const getAuthInfo = (params) => {
  return ajax({
    url: '/login/user',
    method: 'get',
    params
  })
}
// 退出登录
export const logout = (params) => {
  return ajax({
    url: '/logout',
    method: 'get',
    params
  })
}
