const en = {
  header: {
    navs: {
      user: 'Host',
      meeting: 'Meeting',
      myMeeting: 'My Meeting'
    },
    button: {
      login: 'Login'
    }
  },
  table: {
    emptyText: 'No Data'
  },
  userPage: {
    title: 'Host Management',
    createDialog: {}
  },
  userDetailPage: {
    title: 'Host Detail'
  },
  meetingPage: {
    title: 'Meeting Management'
  },
  meetingDetailPage: {
    title: 'Meeting Detail'
  }
}
export default en
