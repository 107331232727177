import { createI18n } from 'vue-i18n'
import zhCn from './zh_cn'
import en from './en'
const messages = {
  zh_cn: zhCn,
  en: en
}

const i18n = createI18n({
  // 使用 Composition API 模式，则需要将其设置为false
  legacy: false,
  // 全局注入 $t 函数
  globalInjection: true,
  locale: localStorage.getItem('lang') || 'zh_cn',
  messages
})

export default i18n
