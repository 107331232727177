import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
import { getUrlParam, handleLogin } from '@/utils/utils'
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60
})

instance.defaults.headers.common.Authorization = localStorage.getItem('Authorization') || ''

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  if (response.status === 200 && response.data.code === 0) {
    return Promise.resolve(response.data)
  } else {
    ElMessage({
      type: 'error',
      message: response.data.message
    })
    return Promise.reject(new Error())
  }
  // 对响应数据做点什么
}, function (error) {
  const errorResponse = error.response
  // 对响应错误做点什么
  if (errorResponse.status == 401) {
    // 未登录交互逻辑
    handleLogin()
    return Promise.reject(new Error())
  } else {
    ElMessage.closeAll()
    ElMessage({
      type: 'error',
      message: '网络连接错误，请稍后再试'
    })
    return Promise.reject(error)
  }
})

export default instance
