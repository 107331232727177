<template>
  <div class="head">
    <a :href="logoUrl" target="_blank">
    <img class="logo" src="@/assets/img/logo2.png">
    </a>
    <div class="nav">
      <div
       :class="{
        'nav-item': true,
        'active': judgeActive(item)
        }"
        :key="item.id"
        v-for="(item) in navList"
        @click="routerGo(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="btn-group">
      <!-- <button class="btn plain" @click="handleShowLang">
        {{ currentLang() }}
        <div v-show="showLangPopup" class="lang-content">
          <div
            class="lang-item"
            :key="item.id"
            v-for="item in languageList"
            @click.stop="changeLanguage(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </button> -->
      <!-- <button class="btn" @click="handleLogin">{{$t('header.button.login')}}</button> -->
      <!-- <el-dropdown class="user-dropdown" trigger="hover">
        <div class="user-container">
          <el-icon v-if="userInfo&&userInfo.user_name" class="avatar" :size="30" color="#1FA499">
            <Avatar />
          </el-icon>
          <span class="user-name">{{ userInfo&&userInfo.user_name || '' }}</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="handleLogout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
      <el-icon v-if="userInfo&&userInfo.user_name" class="avatar" :size="30" color="#1FA499">
        <Avatar />
      </el-icon>
      <span class="user-name">{{ userInfo&&userInfo.user_name || '' }}</span>

    </div>
  </div>
</template>
<script>
import { Avatar } from '@element-plus/icons'
import { logout } from '@/api/login'
import { handleLogout } from '@/utils/utils'
export default {
  components: {
    Avatar
  },
  data () {
    return {
      languageList: [
        { id: 'zh_cn', name: '中文' },
        { id: 'en', name: 'English' }
      ],
      showLangPopup: false,
      isAdmin: false,
      logoUrl: process.env.VUE_APP_LOGO_URL
    }
  },
  mounted () {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    this.isAdmin = !!userInfo.roleLength
    // this.isAdmin =true
  },
  computed: {
    userInfo: {
      get () {
        return JSON.parse(localStorage.getItem('userInfo') || '{}')
      },
      set (val) {
      }
    },
    navList: {
      get () {
        if (this.isAdmin) {
          return [
            { id: 'nav1', name: this.$t('header.navs.user'), path: '/' },
            { id: 'nav2', name: this.$t('header.navs.meeting'), path: '/meeting' },
            { id: 'nav3', name: this.$t('header.navs.myMeeting'), path: '/myMeeting' }
          ]
        }
        return [
          { id: 'nav3', name: this.$t('header.navs.myMeeting'), path: '/myMeeting' }
        ]
      }
    }
  },
  methods: {
    judgeActive (item) {
      var route = this.$route
      if (item.path == route.path) {
        return true
      }
      return false
    },
    currentLang () {
      var showText = '中文'
      var lang = this.$i18n.locale || localStorage.getItem('lang')
      this.languageList.map(item => {
        if (item.id == lang) {
          showText = item.name
        }
      })
      return showText
    },
    routerGo (item) {
      this.$router.push(item.path)
    },
    changeLanguage (item) {
      this.showLangPopup = false
      localStorage.setItem('lang', item.id)
      this.$i18n.locale = item.id
      console.log(this.showLangPopup)
    },
    handleShowLang () {
      this.showLangPopup = !this.showLangPopup
    },
    handleLogin () {
      this.$message.info('功能开发中')
    },
    handleLogout () {
      logout().then(res => {
        if (res.code === 0) {
          handleLogout()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .logo {
    margin-left: 7.2%; /** 138px */
    height: 70px;
  }
  .nav {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 6.56%; /** 126px */
    font-size: 16px;
    font-weight: 500;
    .nav-item {
      height: 100%;
      margin-right: 88px;
      cursor: pointer;
      line-height: $headerHeight;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        font-weight: bold;
        color: $colorGreen;
      }
      &:hover {
        font-weight: bold;
        color: $colorGreen;
      }
    }
  }
  .btn-group {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 7.2%; /** 138px */
  }
  .user-dropdown {
    &:focus-visible {
      outline: none;
    }
  }
  .user-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .btn {
    min-width: 88px;
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid $colorGreen;
    color: white;
    background-color: $colorGreen;
    cursor: pointer;
    &.plain {
      position: relative;
      background-color: white;
      color: $colorGreen;
      margin-right: 20px;
    }
  }
}

.lang-content {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 60px;
  width: 160px;
  border-radius: 20px;
  text-align: center;
  background-color: $colorGreen;
  color: white;
  font-size: 16px;
  transform: translateX(-50%);
  padding: 10px 0;
  .lang-item {
    line-height: 35px;
    padding: 0 5px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.user-name {
  margin-left: 5px;
}
</style>
