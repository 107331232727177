<template>
  <div class="container">
    <div class="header">
      <Header></Header>
    </div>
    <div class="main">
      <div class="sidebar"></div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Header from './header.vue'
export default {
  components: {
    Header
  },
  data () {}
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .header {
    position: relative;
    z-index: 2;
    width: 100%;
    height: $headerHeight;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    // border-bottom: 1px solid $bgColor;
  }
  .main {
    width: 100%;
    height: calc(100% - $headerHeight);
    .sidebar {
      display: inline-block;
      width: $sidebarWidth;
      height: 100%;
    }
    .content {
      display: inline-block;
      width: calc(100% - $sidebarWidth);
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>
