import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Layout from '@/views/layout/index.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Host',
        component: () => import('@/views/user/index.vue')
      },
      {
        path: '/hostDetail',
        name: 'HostDetail',
        component: () => import('@/views/user/detail.vue')
      },
      {
        path: '/meeting',
        name: 'Meeting',
        component: () => import('@/views/meeting/index.vue')
      },
      {
        path: '/meetingDetail',
        name: 'MeetingDetail',
        component: () => import('@/views/meeting/detail.vue')
      },
      {
        path: '/myMeeting',
        name: 'MyMeeting',
        component: () => import('@/views/my-meeting/index.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/404/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
