import { ElMessage, ElLoading } from 'element-plus'
// 复制方法
export function handleCopy (text) {
  var input = document.createElement('input') // 创建input对象
  input.value = text // 设置复制内容
  document.body.appendChild(input) // 添加临时实例
  input.select() // 选择实例内容
  document.execCommand('Copy') // 执行复制
  document.body.removeChild(input) // 删除临时实例
  ElMessage({
    type: 'success',
    message: '复制成功'
  })
}
// 解析浏览器url参数
export function getUrlParam (url) {
  const obj = {}
  if (url.indexOf('?') !== -1) {
    const str = url.substr(url.indexOf('?') + 1)
    const arr = str.split('&')
    for (const val of arr) {
      obj[val.split('=')[0]] = decodeURI(val.split('=')[1])
    }
  }
  return obj
}

// 退出登录逻辑
export function handleLogout () {
  localStorage.removeItem('Authorization')
  localStorage.removeItem('userInfo')
  ElMessage.closeAll()
  const loading = ElLoading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  // var localWebUrl = location.origin
  // var jumpUrl = 'http://192.168.9.163:8089/cas/login' + '?service=' + encodeURIComponent(localWebUrl) + '&appId=C652A27FDD21B68F4C6FC706B0A4F155'
  var jumpUrl = process.env.VUE_APP_LOGIN_URL + ':3001/portal/index'
  window.location.href = jumpUrl
}
// 登录逻辑
export function handleLogin () {
  localStorage.removeItem('Authorization')
  localStorage.removeItem('userInfo')
  ElMessage.closeAll()
  const loading = ElLoading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  var localWebUrl = location.origin + location.pathname
  var jumpUrl = process.env.VUE_APP_LOGIN_URL + '/cas/login' + '?service=' + encodeURIComponent(localWebUrl)
  // var jumpUrl = 'http://192.168.9.163:3001/portal/index'
  window.location.href = jumpUrl
}
