import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import i18n from './i18n/index'
// 全局样式
import './assets/css/index.scss'
import './permission'
const vueApp = createApp(App)
vueApp.use(store).use(router).use(ElementPlus, { locale: zhCn }).use(i18n).mount('#app')
